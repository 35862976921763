.media--list {
  display: flex;
  flex-direction: column;
  .search {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .media--list_wrapper {
    .pagination {
      margin-bottom: 12px;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    .media--list_container {
      display: flex;
      flex-direction: row;
      width: 100%;
      flex-wrap: wrap;
      justify-content: center;
      .media--card {
        display: flex;
        flex-direction: column;
        border: 1px solid #dfdfdf;
        margin-bottom: 12px;
        margin-right: 12px;
        min-width: 240px;
        max-width: 240px;
        .media--preview {
          min-height: 160px;
          background-repeat: no-repeat;
          background-size: cover;
          background-color: #dfdfdf;
        }
        .media--info, .media--actions {
          padding: 8px;
        }
      }
    }
  }
}


.media--selected {
  display: flex;
  flex-direction: column;
  max-width: 480px;
  img {
    width: 100%;
    height: auto;
  }
  .media--actions {
    margin-top: 12px;
  }
}
